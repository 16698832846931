<script setup lang="ts">
interface Props {
        filterByContact?: _VTI_TYPE_Contact | string | null;
        withCreateButton?: boolean;
        multiple?: boolean;
        initialValue?: _VTI_TYPE_Worksite | null;
        closeOnSelect?: boolean;
        submenuPosition?: 'left-0' | 'right-0';
        createButtonFields?: _VTI_TYPE_Record;
    }
    
    import { EndpointResponse } from '~~/app/base/endpoints/types'
    import { ModelType } from '~~/app/base/schemas/BaseSchema'
    import EndpointFactory from '~~/app/factories/EndpointFactory'
    import WorksiteCollection from '~~/app/worksite/collections/WorksiteCollection'
    import WorksiteEndpoint from '~~/app/worksite/endpoints/WorskiteEndpoint'
    
    const emit = defineEmits(['selected', 'hidden']);
    
    const props = withDefaults(defineProps<Props>(), {
        filterByContact: null,
        withCreateButton: false,
        multiple: false,
        initialValue: null,
        closeOnSelect: true,
        submenuPosition: 'left-0',
    });
    const loading = ref(true);
    const submenu = ref(null);
    const worksites = ref<WorksiteCollection | null>(null);
    const selected = ref<Worksite | Array<Worksite> | null>(null);
    const query = ref<string>('');
    const isSelected = (worksite: Worksite) => {
        return selected.value && worksite && selected.value.getId() == worksite.getId();
    };
    const select = (worksite: Worksite | null) => {
        selected.value = worksite;
        emit('selected', worksite);
        if (props.closeOnSelect) {
            submenu.value?.hide();
        }
    };
    const setValue = (worksite: Worksite) => {
        selected.value = worksite;
    };
    const getWorksites = async (force = false) => {
        if (worksites.value && !force) {
            loading.value = false;
            return;
        }
        loading.value = true;
        const endpoint = EndpointFactory.make(ModelType.WORKSITES) as WorksiteEndpoint;
        const params: { [k: string]: any } = {
            include: 'contact',
            filter: {
                search: query.value,
            },
            page: {
                size: 5,
            },
        };
        if (props.filterByContact) {
            params.filter.contact = {
                id: [props.filterByContact.getId()],
            };
        }
        const response = await endpoint.index(params);
        if (response.error) {
            console.error('Could not retrieve worksites.');
            submenu.value?.hide();
            return;
        }
        worksites.value = response.data;
        loading.value = false;
    };
    const show = () => {
        if (!submenu.value?.isDisplayed) {
            submenu.value?.show();
        }
    };
    useListen('worksite:worksite:created', (worksite) => {
        if (props.withCreateButton) {
            select(worksite);
        }
    });
    const openCreateForm = () => {
        const fields = {
            ...(props.createButtonFields || {}),
            ...{ contact: props.filterByContact },
        };
        useEvent('worksite:worksite:form-modal:open', { fields });
    };
    defineExpose({
        show,
        setValue,
    });
    onMounted(async () => {
        if (props.initialValue && typeof props.initialValue !== 'string' && typeof props.initialValue?.isModelOrCollection === 'function') {
            selected.value = props.initialValue;
            return;
        }
        if (typeof props.initialValue === 'string' && props.initialValue !== '') {
            const { load, model } = useModelLoader(ModelType.WORKSITES, props.initialValue);
            await load();
            if (model.value) {
                selected.value = model.value;
            }
        }
    });
</script>

<template>
    <Submenu
        ref="submenu"
        :position="submenuPosition"
        :container="$theme('submenu.container.xl')"
        max-height="max-h-[400px]"
        @shown="getWorksites"
        @hidden="emit('hidden', selected)"
    >
        <template #default="slotProps">
            <div
                class="px-3 py-2 transition-all border border-gray-200 rounded cursor-pointer hover:bg-neutral-100 active:bg-neutral-200 hover:text-primary group"
                @click.prevent="slotProps.toggle()"
                @contextmenu="($event) => (selected ? selected.openContextMenu($event) : null)"
            >
                <div class="flex items-center space-x-2">
                    <div>
                        <div
                            v-if="selected"
                            class="w-3 h-3 mt-[2px] rounded-full"
                            :style="{ backgroundColor: selected.color }"
                        ></div>
                        <i
                            v-else
                            class="transition-all text-neutral-600 group-hover:text-primary fa-regular fa-helmet-safety"
                        ></i>
                    </div>
                    <div class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                        <span
                            class="text-sm transition-all text-neutral-500 group-hover:text-primary"
                            v-text="selected ? selected.title : $t('selects.worksites.placeholder')"
                        ></span>
                    </div>
                    <template v-if="withCreateButton">
                        <a
                            href="#"
                            :class="$theme('button.style.gray_xs', 'hover:bg-neutral-300')"
                            @click.stop.prevent="openCreateForm"
                        >
                            <span class="inline">
                                <i class="fa-regular fa-plus" />
                            </span>
                        </a>
                    </template>
                </div>
            </div>
        </template>

        <template #submenu>
            <div class="w-full min-w-[260px]">
                <div class="mb-2">
                    <p>
                        {{ $t('selects.worksites.title') }}
                    </p>
                    <p
                        v-if="filterByContact"
                        class="block text-xs text-neutral-500 max-w-[300px] whitespace-normal"
                    >
                        {{
                            $t('selects.worksite.filtered_by_contact', {
                                name: filterByContact.name,
                            })
                        }}
                    </p>
                </div>
                <div class="relative mb-2">
                    <input
                        v-model="query"
                        type="search"
                        class="w-full py-2 pl-4 pr-8 text-sm rounded-lg bg-neutral-100"
                        :placeholder="$t('selects.worksites.search_placeholder')"
                        @keyup.enter.prevent="getWorksites(true)"
                    />
                    <div class="absolute top-0 bottom-0 right-0 flex items-center pr-2">
                        <i
                            class="text-sm transition-all cursor-pointer text-neutral-600 fa-regular fa-search hover:text-black"
                            @click.prevent.stop="getWorksites(true)"
                        ></i>
                    </div>
                </div>
                <div class="relative">
                    <div
                        v-if="loading"
                        class="py-6"
                    >
                        <Loader></Loader>
                    </div>
                    <template v-else>
                        <SubmenuEmptyContent v-if="worksites?.isEmpty()" />
                        <div
                            v-else
                            class="flex flex-col"
                        >
                            <div
                                v-for="worksite in worksites?.get()"
                                :key="worksite.getInternalId()"
                                :class="[$theme('contextmenu.link', 'cursor-pointer'), { 'bg-neutral-100': isSelected(worksite) }]"
                                @click.prevent="select(worksite)"
                            >
                                <div>
                                    <div
                                        class="w-3 h-3 mt-[2px] rounded-full"
                                        :class="{
                                            'opacity-50 group-hover:opacity-100': !isSelected(worksite),
                                        }"
                                        :style="{ backgroundColor: worksite.color }"
                                    ></div>
                                </div>
                                <div>
                                    <span class="block text-sm">{{ $truncate(worksite.title, 44) }}</span>
                                    <span class="block text-xs">
                                        {{ worksite.startsAt.toShortDayDateString() }} -
                                        {{ worksite.endsAt.toShortDayDateString() }}
                                    </span>
                                </div>
                                <div
                                    v-if="isSelected(worksite)"
                                    class="absolute top-0 bottom-0 right-0 flex items-center pr-3"
                                >
                                    <a
                                        href="#"
                                        @click.prevent.stop="select(null)"
                                    >
                                        <i class="text-neutral-400 fa-regular fa-xmark hover:text-neutral-600"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <template v-if="withCreateButton">
                            <a
                                href="#"
                                :class="$theme('contextmenu.link')"
                                @click.stop.prevent="openCreateForm"
                            >
                                <i
                                    class="fa-plus"
                                    :class="$theme('contextmenu.icon')"
                                ></i>
                                <span>{{ $t('quick_actions.add_worksite') }}</span>
                            </a>
                        </template>
                    </template>
                </div>
            </div>
        </template>
    </Submenu>
</template>
